.circle {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: inline-block;
    line-height: 40px;
    margin-right: 10px;
    margin-left: 10px;
}

.\+red {
    background-color: #FF495D;
}

.\+yellow {
    background-color: #FFD45F;
}

.\+green {
    background-color: #6FB771
}