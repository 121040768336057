.stats-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 30rem;
  overflow: auto;
  background: #fff;
  border-radius: 15px;
  padding: 1rem 3rem;
  margin-bottom: 1rem;
}

.stats-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.no-data {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.stats-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.stats-row-empty {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.stats-totals {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.stats-graphs {
  display: flex;
  justify-content: center;
}