
.MuiTab-root {
  text-transform: none !important;
}

.\+right {
  float: right;
}

.active {
  font-weight: bolder !important;
}