.sync-icon {
  margin-right: 0.5rem;

  &_offline {
    @extend .sync-icon;
    transform: rotate(90deg);
    fill: Silver !important;
  }

  &_good {
    @extend .sync-icon;
    transform: rotate(90deg);
    fill: #6fb771 !important;
  }

  &_alert {
    @extend .sync-icon;
    fill: #ffd45e !important;
  }

  &_warning {
    @extend .sync-icon;
    transform: rotate(90deg);
    fill: #ff495d !important;
  }
}
