.stats-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: #fff;
  border-radius: 15px;
  padding: 1rem 3rem;
  margin-bottom: 1rem;
}

.stats-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.no-data {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.stats-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.stats-row-empty {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.stats-totals {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.stats-graphs {
  display: flex;
  justify-content: center;
}

.checks-button {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem 3rem 1rem 3rem;
}

.filterBar {
  display: flex;
  margin-bottom: 10px;
}

.select-control {
  margin-right: 1rem;
  width:200%;
}