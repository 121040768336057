.container {
  width: 100%;
  height: 100%;
}

.env-select {
  display: flex;
  margin-bottom: 2rem;
}

.select-control {
  margin-right: 1rem;
}

.flex {
  display: flex;
  margin-bottom: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.align-middle {
  display: flex;
  align-items: center;
}