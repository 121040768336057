.app {
  text-align: center;
  height: 100vh;
  background-color: #ececec;
  overflow:auto;
}

.app__container {
  padding: 2em 2em 0 2em;
}

.login {
  display: flex;
  justify-content: center;
}

.dialog {
  width: 25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enabled {
  background-color: #3e90c7;
  color: white;
}

.disabled {
  background-color: lightGray;
  color: white;
  cursor: not-allowed;
}

.disabled-scrubber {
  color: lightGray;
  cursor: not-allowed;
}

.error {
  font-size: 1rem;
  color: red;
}

.no-scroll::-webkit-scrollbar {
  display:none;
}
