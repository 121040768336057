.ps-bpa {
  padding: 1rem;
}

.ps-bpa-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ps-bpa-submit-time {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ps-reports {
  display:flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem 2rem;
}

.ps-reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}

.ps-reports-content {
  display: flex;
  flex-direction: column;
}

.ps-reports-row {
  display: flex;
  align-items: flex-start;
}

.ps-reports-section {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 3px solid rgb(156, 204, 216);
  border-radius: 10px;
}

.device-remove-modal-action {
  display: flex;
  justify-content: space-evenly;
}

.paper-style {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex: 1 1 auto;
}
