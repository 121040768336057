.render-validation-status {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 4rem;
  margin-bottom: 1rem;
}

.render-validation-status-text {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.findings-table {
  overflow-x: auto;
}

.customer-finding {
  display: flex;
  padding: 0 6rem;
  justify-content: center;
  overflow: auto;
}
